import "../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@18.19.43_sass@1.77.8_terser@5.31.4_webpack@5.93.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ffeatures%2Fweb%2Fcomponents%2Fplace-review%2FplaceReview.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA81Xy27rNhDd%2ByvYCxSwF0wkRXF86U3%2FJKCokc2GIlWS8qNF%2Fr2gXqZkSbYvjKLIxiKHnJkzh3MmL59hRsu%2F1ixA%2FywQyqnecUlQFBcnFAXFCb1FxWm7%2BF68tIZhZZhyUwh6JigTcNouEPqzNJZnZ8yUtCAtQaagDHAC9gggnQUVfCcxt5AbghhIC3rbucSJslblBIWVP4QyJS0%2BAt%2FtLUEHqpcYe0s4USJddXaG%2Fw09K7eAw4%2FVIPLuZ1QlwZRQuj1n4WRxQg1nmNHCciVXXngCMktQPMDirbqmUIY7e4I0CGr5AXpG8Z2AXSCZQGou1fcq2KmEUjBM80tSN8CVSudU9MGLCUkgUxoa5Jqgf%2FzY9hCgiVGitOBWrSoIwmFdzxpAHL7Xn6qgjNszQcHQC80s6DEnHYKJUOzLrRx5avcEhev6zn2TUPudUPa106qUKeY53QFBpRbLV2oMWPNaLZlXztQn1VodP7U7%2FWIOu9XgcA05U4e6DN6OhgKoJUiq5ucgGcocG3rIjUPVZPKx6WcSNWglSqegsaYpL01DQw%2FDcBBWjwZ%2BIqXO3KO0qmR7bKnege1X%2Bb2Kceoqm6uE40SUgMMgWPklCILf%2FcB%2FBnWIT2D9IPe6uF7I6yf3o4mM1g3obYd0fQoFqClYQdOUyx2peuZcNQoqwFrwoRTOARi7umRLkFGCpygsTrfO6dUYSE0TfagfzHQWD%2B6PCu4GJEYFWzqkEEYbJxm4cr26r%2FRuA4NMb4tD3TzCoRR9oJQfxso%2FtCGCGotVhu25AMcE2VO7oPpz8f%2FG80JpS6UdceTOkYxr76r%2BLeGm4sRQJTaeGdY1nTYjBdppem4elnc6mdQYt8odUhgOIK0hiJZWjXC4Y6cT81rWW55WpKjAv35zHQtf4o6HHv%2FemhYwwb1EnbDZ01QdCXIe18WpeTTdZYmg7AtTUewpDoNnKlgOKS%2FzR7mdzKjPjMQFF30LZrQt6eTg4mT81mFZpZIw05ra72fpwXVJwwGjE1QQafftM1hGK5%2FiFSgbb4ybQH9zTyW7QW%2BaGRktxUDHmN%2BlMm7bptN7DY7Vm0FXd2vuFdWVnMKweqnv84z1h8g5FKIxxKP4ril4bFBL%2FdSvOkD7Ov1S3dKsHmMYA2OwAaZkSvX5GoIRa1fDcaHaDIgFM3V7wizR%2FXsTtGV%2BiwYCHt%2FmbfxgB7qvp%2Fko%2FOKsHfmj9lwrgl8as%2BPBmB3%2FP8ZseHjMHjbQ%2F3zM9maQKXJQ5mj7QIf8XvzhOEeRYRpAIipTtMzpCTdJvznS1336Mpn0ByO35xGglZ1v%2F8jPS%2BgIHffcAq4ma2d%2B1LTYVhuuiJlw0r%2FnaVrP282wcdkCIXhhuKk3uzHqyiWdDuz7X8%2B1T6zAEAAA%22%7D"
export var banner = '_1fauq6c5';
export var btn_more = '_1fauq6c4';
export var btn_more_wrapper = '_1fauq6c3';
export var count = '_1fauq6c2';
export var lastReaction = '_1fauq6c9';
export var lastReactionImage = '_1fauq6ca';
export var moreReviewBtn = '_1fauq6ce';
export var pipe = '_1fauq6c8';
export var reactionWrapper = '_1fauq6c6';
export var reactions = '_1fauq6c7';
export var reviewBtn = '_1fauq6cd';
export var reviewModuleWrapper = '_1fauq6cb';
export var title = '_1fauq6c1';
export var tripDateTag = '_1fauq6cc';
export var wrapper = '_1fauq6c0';