import { AdType, MyReviewResponse } from '@tmap-web-lib/remote-api-client/frontman'
import { RedDotListData, TmapApp, UserAllSettings } from '@tmap-web-lib/tmap-app-interface'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { usePageIdContext } from '@/Providers'
import { TmapLogContextProvider, useTmapLogContext } from '@/Providers/tmap-log-context'
import { MainSkeleton } from '@/components'
import { store, useAppDispatch } from '@/features/store'
import {
  STORAGE_SCHEME_URL,
  getSettingsState,
  setIsSkeleton,
  setSchemeURL,
} from '@/features/store/settings'
import {
  AdsBanner,
  PlaceReview,
  ServiceList,
  UserProfile,
  UserProfileProps,
  VehicleInfo,
  VehicleInfoProps,
} from '@/features/web/components'
import {
  useGetAdsServing,
  useGetMyWholeReview,
  useGetParkingList,
  useGetTmapPayPointsSummary,
  useGetUserInfo,
} from '@/react-query'
import { fuelTypes, vehicleModelTypes } from '@/types'
import { storageUtil, tmapDateStringToDateTime } from '@/utils'

import '../../styles/fonts.css'
import * as styles from '../../styles/pages/web.css'

export default function Web() {
  const dispatch = useAppDispatch()
  const { remoteConfigServiceList } = useSelector(getSettingsState)
  const { tmapLogClient } = useTmapLogContext()
  const pageIdContext = usePageIdContext()
  const { data: bannerList, isInitialLoading: isBannerLoading } = useGetAdsServing({
    inventoryCode: 'IN104',
    useErrorBoundary: false,
  })
  const {
    data: pointData,
    isInitialLoading: isPointDataLoading,
    refetch: refetchPointData,
  } = useGetTmapPayPointsSummary({ useErrorBoundary: false })
  const {
    data: userInfoData,
    isInitialLoading: isUserInfoDataLoading,
    refetch: refetchUserInfoData,
  } = useGetUserInfo()
  const {
    data: parkingData,
    isInitialLoading: isParkingDataLoading,
    refetch: refetchParkingData,
  } = useGetParkingList({ useErrorBoundary: false })
  const {
    data: reviewData,
    isInitialLoading: isReviewDataLoading,
    refetch: refetchReviewData,
  } = useGetMyWholeReview({ useErrorBoundary: false })
  const [isVerified, setIsVerified] = useState<boolean | null>(null)
  const [bannerData, setBannerData] = useState<AdType[] | null>(null)
  const [review, setReview] = useState<MyReviewResponse | null>(null)
  const [isMainLoading, setIsMainLoading] = useState(true)
  const [redDotList, setRedDotList] = useState<RedDotListData[]>([])
  const [pointRedDot, setPointRedDot] = useState<RedDotListData[]>([])
  const [serviceListRedDot, setServiceListRedDot] = useState<
    Pick<RedDotListData, 'itemGroup' | 'item'>[]
  >([{ itemGroup: '', item: '' }])
  const [userProfileData, setUserProfileData] = useState<UserProfileProps>({
    loginAuthType: '',
    image: '',
    name: '',
    mdn: '',
    tid: '',
    point: 0,
    isVerified: null,
  })
  const [vehicleInfo, setVehicleInfo] = useState<VehicleInfoProps>({
    carNumber: '',
    vehicleType: '',
    fuelType: '',
    isParking: null,
  })

  const getUserProfile = useCallback(() => {
    Promise.all([TmapApp.getLoginMethod(), TmapApp.getUserAllSettings()]).then(
      ([loginAuthType, userSettings]) => {
        const settings = userSettings as UserAllSettings
        console.log('user all settings === ', settings)
        const vehicleType =
          vehicleModelTypes.find((model) => model.model === settings?.settings.car.model)?.name ??
          '승용차'

        setUserProfileData({
          loginAuthType: loginAuthType ? loginAuthType : 'None',
          image: settings ? settings.settings.user.image : '',
          name: settings ? settings.settings.user.name : '',
          mdn: userInfoData?.mdn ? userInfoData.mdn : '',
          tid:
            loginAuthType === 'TID' && userInfoData
              ? userInfoData.externalUserInfos[0].readableUserId
              : '',
          point: pointData ? pointData.availablePoint : 0,
          isVerified,
        })

        setVehicleInfo({
          carNumber: settings
            ? vehicleType === '이륜차' && !settings.settings.car.number
              ? '모터바이크'
              : settings.settings.car.number
            : '',
          vehicleType,
          fuelType:
            vehicleType === '이륜차'
              ? ''
              : settings
                ? fuelTypes[settings.settings.car.fuel]
                : '휘발유',
          isParking:
            vehicleType === '이륜차'
              ? false
              : parkingData?.length && settings
                ? parkingData.some(
                    (vehicle) => vehicle.vehicleNumber === settings.settings.car.number
                  )
                : false,
        })
      }
    )
  }, [userInfoData, parkingData, pointData, isVerified])

  const getRedDotList = useCallback(() => {
    TmapApp.getRedDotList().then((redDotList) => {
      const result = redDotList.filter(
        (data) =>
          (data.itemGroup === 'SERVICE_INDEX' ||
            data.itemGroup === 'COMMUNICATION' ||
            data.itemGroup === 'HOME') &&
          data.displayType === 'REDDOT' &&
          tmapDateStringToDateTime(data.expiredDateTime) > DateTime.now() &&
          tmapDateStringToDateTime(data.startDateTime) <= DateTime.now() &&
          !data.readDateTime
      )
      console.log('reddot result', result)
      setRedDotList(result)
    })
  }, [])

  useEffect(() => {
    if (redDotList) {
      const serviceListRedDot = redDotList.map((data) => ({
        itemGroup: data.itemGroup,
        item: data.item,
      }))
      const pointRedDot = redDotList.filter((data) => data.item === 'POINT')
      setServiceListRedDot(serviceListRedDot)
      setPointRedDot(pointRedDot)
    }
  }, [redDotList])

  useEffect(() => {
    if (isVerified !== null) {
      getUserProfile()
      getRedDotList()
    }
  }, [isVerified, getUserProfile, getRedDotList])

  useEffect(() => {
    if (userInfoData) {
      if (userInfoData.idVrfdYn === 'Y') {
        setIsVerified(true)
      } else {
        setIsVerified(false)
      }
    }
  }, [userInfoData])

  useEffect(() => {
    if (bannerList) {
      setBannerData(bannerList.ads)
    }
  }, [bannerList])

  useEffect(() => {
    console.log('reviewData', reviewData)
    if (reviewData) {
      setReview(reviewData)
    }
  }, [reviewData])

  useEffect(() => {
    if (
      !isUserInfoDataLoading &&
      !isBannerLoading &&
      !isParkingDataLoading &&
      !isPointDataLoading &&
      !isReviewDataLoading &&
      remoteConfigServiceList
    ) {
      setIsMainLoading(false)
    }
  }, [
    isUserInfoDataLoading,
    isBannerLoading,
    isParkingDataLoading,
    isPointDataLoading,
    isReviewDataLoading,
    remoteConfigServiceList,
  ])

  useLayoutEffect(() => {
    if (window.location.pathname === '/web') {
      store.dispatch(setIsSkeleton(true))
    }
  }, [])

  // // android resume시 repaint
  // useEffect(() => {
  //   if (TmapApp.env.isInApp) {
  //     const off = TmapApp.utils.addNativeEventListener('onResume', () => {
  //       const bodyEl = document.querySelector('body') as HTMLBodyElement
  //       bodyEl.style.transform = 'translate3d(0, 0, 0)'
  //       setTimeout(() => (bodyEl.style.transform = ''), 50)
  //     })
  //     return () => off()
  //   }
  // }, [])

  useEffect(() => {
    return TmapApp.utils.addNativeEventListener('onResume', async () => {
      getUserProfile()
      getRedDotList()
      await refetchPointData()
      await refetchUserInfoData()
      await refetchParkingData()
      await refetchReviewData()
      dispatch(setIsSkeleton(false))
      setTimeout(() => store.dispatch(setIsSkeleton(true)), 1500)
    })
  }, [
    dispatch,
    getUserProfile,
    getRedDotList,
    refetchPointData,
    refetchUserInfoData,
    refetchParkingData,
    refetchReviewData,
  ])

  useEffect(() => {
    return TmapApp.utils.addNativeEventListener('onRefresh', () => {
      window.location.reload()
    })
  }, [])

  useEffect(() => {
    if (!isMainLoading) {
      tmapLogClient('expose').setPageId('/my').send()
    }
  }, [isMainLoading, tmapLogClient])

  useEffect(() => {
    const { extraObject, pageIdInfo } = pageIdContext
    const encodedLogBoxObject = encodeURIComponent(
      JSON.stringify({
        activeArea: extraObject.activeArea,
        logbox: extraObject.logbox,
      })
    )
    if (TmapApp.env.appVersion !== '0.0.0') {
      // 마이 메인이 항상 떠있는 웹뷰라 스킴으로 진입했을 때 히스토리가 쌓이거나 밑에 메인 페이지가 한 번 떠 깔리는 이슈 있음
      // AOS의 경우 replace 해도 히스토리가 쌓이는 이슈 있어 로그 정보를 가지고있는 url을 스토어에 저장 후 history.back으로 돌아가주고 (0번째 페이지로 가기 위함)
      // 저장된 url이 있으면 replace 해주도록해서 히스토리가 쌓이지 않도록 적용
      if (TmapApp.env.isAndroid) {
        if (pageIdInfo) {
          let path = `/web?extra=${encodedLogBoxObject}`
          store.dispatch(setSchemeURL(path))
          window.history.back()
        }
        if (storageUtil.getItem(STORAGE_SCHEME_URL)) {
          const schemeUrl = storageUtil.getItem(STORAGE_SCHEME_URL) as string
          window.location.replace(schemeUrl)
          setTimeout(() => store.dispatch(setSchemeURL('')), 0)
        }
      } else {
        if (pageIdInfo) {
          window.location.replace(`/web?extra=${encodedLogBoxObject}`)
        }
      }
    }
  }, [pageIdContext])

  return (
    <div className={styles.wrapper}>
      {isMainLoading ? (
        <MainSkeleton />
      ) : (
        <TmapLogContextProvider pageId={'/my'}>
          <div className={styles.interfaceArea} />
          <UserProfile
            loginAuthType={userProfileData.loginAuthType}
            image={userProfileData.image}
            name={userProfileData.name}
            mdn={userProfileData.mdn}
            tid={userProfileData.tid}
            point={userProfileData.point}
            isVerified={userProfileData.isVerified}
            redDotList={pointRedDot}
          />
          <VehicleInfo
            carNumber={vehicleInfo.carNumber}
            vehicleType={vehicleInfo.vehicleType}
            fuelType={vehicleInfo.fuelType}
            isParking={vehicleInfo.isParking}
          />
          <PlaceReview data={review} />
          <AdsBanner
            data={bannerData}
            isLoading={isBannerLoading}
          />
          <ServiceList redDotList={serviceListRedDot} />
        </TmapLogContextProvider>
      )}
    </div>
  )
}
