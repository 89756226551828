import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { FamilyAppsRes } from '@tmap-web-lib/remote-api-client/dist/types/frontman/apis/common/channel-app/get-family-apps'
import { getFamilyApps } from '@tmap-web-lib/remote-api-client/frontman'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { AxiosError } from 'axios'

import { KEYS } from '@/react-query'

function useGetFamilyApps(): UseQueryResult<FamilyAppsRes, AxiosError> {
  const appVersion = TmapApp.env.isInApp ? TmapApp.env.appVersion : '10.9.0'
  const osType = TmapApp.env.isInApp ? (TmapApp.env.isAndroid ? 'AND' : 'iOS') : 'iOS'

  return useQuery<FamilyAppsRes, AxiosError>([KEYS.FAMILY_APPS()], () =>
    getFamilyApps({ appVersion, osType }).then(({ data }) => data)
  )
}

export { useGetFamilyApps }
