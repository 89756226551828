import "../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@18.19.43_sass@1.77.8_terser@5.31.4_webpack@5.93.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Ffeatures%2Fweb%2Fcomponents%2Fuser-profile%2FuserProfile.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA91V3W6bMBS%2B71NYlSol2pwCJWnm3k17hN3sqjLmQKwa27JNQjf13ScMZJgmtNmmXewqycE5%2Fs73w1k9xvXT09ZFEfpxhZBWljuuJEEGBHV8Dw9tleY5lyVBcaIblES6aas5t1rQZ4IKAc3D1cvVaugV%2B14HnrsdQYwKtoij6AZhdJ%2FoZtn%2Bt6Km5BILKBxB8eZkQ%2BQ%2Fcc4NsA4TU6KuZHBVMgP7VT8qeCkxd1BZghhIB8Zfo6TDln8HgvbULDA%2BFnASLY8nDsDLnQvOdCWcKZH7c4JLwLv%2BXLLaGqhG02bKOVURtNUhXQkhtHBg%2FChMSQfSEXR9%2FRBMRjOrRO38ZB1veN3xpjRl3D0TFE3aUtZSMe7%2BSpMPKI56TQbcbT1oG7e%2FMsqeSqNqmWOmhDIDD6O6rU1BGWCnarbDjpoSnO%2BcKZODwYbmvLYEpdP5Ea9KD29CFJ4yddcNseMOsNWUAUFSHQzVHu8eTCHUgaAdz3OQbc1B4%2FCvByAE15bbkSimG3qKKfU3vaG7VKaiwo8YcOJvzajlDOdgmeG6lXAZXLDuB27wiZwkQ058J29b4kUPWmzmWmw2vsXo%2BP3YAWnSeedo1j7Ux6i3SU%2B72gmmzvuhNPQZr6Ozus%2FGLU7eE7cR7UHghvdIgOdry%2BBnr8WXc1psLw5gz0XkNVKaoHWXGWeotIUyFem%2BCupggTe6%2BYhwnN54zL0CA9op%2BhGxvKIlEFQbsbil1oKzt75kbzlTjxrk48FwByu7L5eTv3b8trNQLifPDGigro1O%2FzUg49PvkoHTXuD%2FjBA6s2J66AV3uKdqFKJ%2BvSXJ275P%2F2DNRJNXF71YQC8Y7tXrd8vd7G6hhGRQKAOX7qx3JebbAq%2Bj0BvbiTe2F3hDKy7dX%2FbEid16euz3bNyTD%2F%2FZ%2Bs1m%2FBKCmHFP3OkxvAo2Yy%2FV0oIbcZGGWg4LJUTZ2%2BPc3AZyvI6i5SSp2g8xXWphXjzUl5%2BChQG2%2BQoAAA%3D%3D%22%7D"
export var name = '_1ukk8t03';
export var nameBtn = '_1ukk8t02';
export var nameUnregistered = '_1ukk8t04';
export var nameWidthVariants = {verified:'_1ukk8t05',notVerified:'_1ukk8t06'};
export var positionRightVariants = {verified:'_1ukk8t08',notVerified:'_1ukk8t09'};
export var unVerifiedTag = '_1ukk8t07';
export var userInfoWrapper = '_1ukk8t01';
export var userPoint = '_1ukk8t0a';
export var userPointRedDot = '_1ukk8t0b _1ukk8t0a';
export var wrapper = '_1ukk8t00';